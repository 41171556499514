import store from '../../store'
// import Vue from 'vue'

const errorHandler = err => {
  if (err?.response?.data?.message === 'jwt expired') {
    console.log('here')
    location.href = '/'
    store.commit('setTokenExpiredState', true)
  }
  return {
    error: true,
    errorMessage: err?.response?.data?.message,
    internalError: err
  }
}

const successHandler = res => {
  return {
    error: false,
    data: res?.data?.data,
    message: res?.data?.message,
    status: res.status
  }
}

const generateQuery = (populate = [], limit = 0) => {}

const generateNoOptionsQuery = (populate = [], limit) => {}

export { errorHandler, successHandler, generateQuery, generateNoOptionsQuery }
